:root {
  --size-3: 3px;
  --size-6: 6px;
  --size-10: 10px;
  --size-12: 12px;
  --size-18: 18px;
  --size-24: 24px;
  --size-30: 30px;
  --size-36: 36px;
  --size-42: 42px;
  --size-48: 48px;
  --size-54: 54px;
  --size-60: 60px;
  --size-72: 72px;
  --size-78: 78px;
  --size-84: 84px;
  --size-96: 96px;
  --size-108: 108px;
  --size-120: 120px;

  --size-container-max-width-md: 864px;
  --size-container-max-width-lg: 1055px;
  --size-container-max-width-xl: 1680px;
  --size-container-padding: 0 5vw;
  --size-container-padding-md: 0 calc(2.5vw + var(--size-24));

  --size-site-header-height-sm: 60px;
  --size-site-header-height-md: 75px;

  --size-input-height: var(--size-48);
}

:export {
  siteHeaderHeightSm: 60px;
  siteHeaderHeightMd: 75px;
}
