:root {
  --font-family-base: 'Graphik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --font-family-heading: 'Produkt', Georgia, Times, 'Times New Roman', serif;

  --font-weight-light: 300;
  --font-weight-base: 400;
  --font-weight-regular: var(--font-weight-base);
  --font-weight-semibold: 600;
  --font-weight-bold: 800;

  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-22: 22px;
  --font-size-24: 24px;
  --font-size-28: 28px;
  --font-size-32: 32px;
  --font-size-42: 42px;
  --font-size-52: 52px;
  --font-size-62: 62px;
  --font-size-72: 72px;
  --font-size-82: 82px;
  --font-size-112: 112px;

  --line-height-base: calc(24 / 16);
  --line-height-heading: 1;
  --line-height-narrow: 1;
}
